<template>
  <div class="coding-container">
    <div class="section-header">
      <SectionHeader headerSize="xSmall" headerText="illustrations" />
    </div>
    <div class="s-header-mid">
      <SectionHeader
        :is-large="false"
        headerSize="xSmall"
        headerText="illustration"
      />
    </div>
    <div class="right-side">
      <Item
        v-for="(item, index) in illustrationData"
        :key="index"
        :date="item.date"
        :description="item.description"
        :image="isDark ? images.dark[index] : images.light[index]"
        :title="item.title"
      />
    </div>
    <!-- Right Side -->
    <BackToTop />
  </div>
</template>

<script>
import {} from "@/core/mixins/inner_page_mixin.js";
import { writtenData } from "@/core/mixins/written_data_mixin.js";
import { colorMixin } from "@/core/mixins/color_mixin.js";
import Item from "@/pages/illustration/desktop/Item.vue";
import lightIcons from "@/assets/images/illustrations/light/system_icons.webp";
import darkIcons from "@/assets/images/illustrations/dark/system_icons_dark.webp";
import lightAlbum from "@/assets/images/illustrations/light/family_album.webp";
import darkAlbum from "@/assets/images/illustrations/dark/family_album_dark.webp";
import darkSystemIcons from "@/assets/images/illustrations/dark/system_icons_dark.webp";
import lightCover from "@/assets/images/illustrations/light/teen_cover.webp";
import darkCover from "@/assets/images/illustrations/dark/teen_cover.webp";
import lightParrot from "@/assets/images/illustrations/light/parrot.webp";
import darkParrot from "@/assets/images/illustrations/dark/parrot_dark.webp";
import lightClimber from "@/assets/images/illustrations/light/rock_climber.webp";
import darkClimber from "@/assets/images/illustrations/dark/rock_climber_dark.webp";
import lightHalloween from "@/assets/images/illustrations/light/halloween_poster.webp";
import darkHalloween from "@/assets/images/illustrations/dark/halloween_poster_dark.webp";
import lightReading from "@/assets/images/illustrations/light/girl_reading.webp";
import darkReading from "@/assets/images/illustrations/dark/girl_reading.webp";
import { scrollMixin } from "@/core/mixins/scroll_mixin";
import SectionHeader from "@/components/section_header/SectionHeader.vue";
import BackToTop from "@/components/controls/button/BackToTop.vue";

export default {
  mixins: [writtenData, colorMixin, scrollMixin],
  components: {
    Item,
    SectionHeader,
    BackToTop,
  },

  data() {
    return {
      images: {
        light: [
          lightIcons,
          lightAlbum,
          lightCover,
          lightParrot,
          lightClimber,
          lightHalloween,
          lightReading,
        ],
        dark: [
          darkIcons,
          darkAlbum,
          darkCover,
          darkParrot,
          darkClimber,
          darkHalloween,
          darkReading,
        ],
      },
    };
  },
};
</script>

<style scoped>
.coding-container {
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 18.25rem 1fr;
  padding-block-start: 6rem;
}

@media screen and (max-width: 875px) {
  .coding-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding-inline: 2rem;
    gap: 4rem;
  }
}

.right-side {
  inline-size: 100%;
  max-inline-size: 51rem;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 5rem;
  padding-inline-end: 2rem;
  box-sizing: border-box;
}

@media screen and (max-width: 875px) {
  .right-side {
    padding: 0;
  }
}

.section-header {
  block-size: fit-content;
  inline-size: fit-content;
}

@media screen and (max-width: 875px) {
  .section-header {
    display: none;
  }
}

.s-header-mid {
  display: none;
}

@media screen and (max-width: 875px) {
  .s-header-mid {
    display: grid;
    block-size: 100%;
    inline-size: 100%;
    padding-inlin: 2rem;
    place-content: start;
  }
}
</style>
